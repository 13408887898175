import { Component, OnInit } from '@angular/core';
import { DataStoreService } from '../data-store.service';

@Component({
  selector: 'app-last-sweep-summary',
  templateUrl: './last-sweep-summary.component.html',
  styleUrls: ['./last-sweep-summary.component.scss']
})
export class LastSweepSummaryComponent implements OnInit {

  constructor(public dataService:DataStoreService) { }

  ngOnInit() {
  }

}
