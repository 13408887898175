import { Component, OnInit } from '@angular/core';
import { DataStoreService } from '../data-store.service';
import { PersonResults } from '../personResults';
import { FlaggedResult } from '../flaggedResult';
import { PdfServiceService } from '../pdf-service.service';

@Component({
  selector: 'app-sweep-results',
  templateUrl: './sweep-results.component.html',
  styleUrls: ['./sweep-results.component.scss']
})
export class SweepResultsComponent implements OnInit {

  constructor(public dataService: DataStoreService, public pdfService: PdfServiceService) { }

  ngOnInit() {
  }
}
