export class Status {
    public loading: boolean = false;
    public mode:string = "determinate";
    public buffer:number = 0;

    private target:number;
    private value:number;

    constructor(target:number){
        this.target = target;
        this.value = 0;
        this.buffer = 0;
    }

    public setValue(value:number){
        this.value = value;
    }

    public setTarget(value:number){
        this.target = value;
    }

    public getProgress():number {
        return (this.value / this.target) * 100;
    }
}