import { FlaggedResult } from "./flaggedResult";
import { DataStoreService } from "./data-store.service";

export class Update {
    public static typeOfUpdate(input): Update {
        if(input.type){
            switch(input.type){
                case "UPDATE": return new MessageUpdate(input);
                case "ERROR": return new ErrorUpdate(input);
                case "RESULT": return new ResultUpdate(input);
                default: throw "Update has unknown type: " + input.type;
            }
        }
        else throw "Update has no type";
    }

    constructor(){}

    public execute(arg){}
}

export class MessageUpdate extends Update {
    public message:string;
    public submessage:string;
    public target:number;
    public progress:number;

    constructor(model){
        super();
        if(model.message) this.message = model.message;
        if(model.submessage) this.submessage = model.submessage;
        if(model.target && model.target >= 0) this.target = model.target;
        if(model.progress && model.progress >= 0) this.progress = model.progress;
    }

    public execute(arg:DataStoreService){
        if(this.message) arg.message = this.message;
        if(this.submessage) arg.status.mode = "indeterminate";
        else arg.status.mode = "determinate";
        if(this.target && this.target >= 0) arg.status.setTarget(this.target);
        if(this.progress && this.progress >= 0) arg.status.setValue(this.progress);
    }
}

export class ErrorUpdate extends Update {
    public message:string;

    constructor(model){
        super();
        if(model.message) this.message = model.message;
    }

    public execute(arg){
        if(this.message === 'Your session has expired. Please log in again to continue.'){
            // Handle logout
            let r = confirm(this.message);
            if(r === true){
                window.location.href = '/logout';
            }
        } else {
            // Alert user of error
            alert(this.message);
        }
    }
}

export class ResultUpdate extends Update {
    public result:FlaggedResult;

    constructor(model){
        super();
        if(model.result){
            // Copy flagged result, because it has lost its class
            let temp = new FlaggedResult();
            this.result = Object.assign(temp,model.result);
        }
    }

    public execute(arg:DataStoreService){
        if(this.result) arg.addData(this.result);
        arg.saveSweep();
    }
}