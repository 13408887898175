import { Injectable } from '@angular/core';
import { PersonResults } from './personResults';
import { Filter } from './filter';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ImportOptions } from './importOptions';
import { FlaggedResult } from './flaggedResult';

@Injectable({
  providedIn: 'root'
})
export class PdfServiceService {

  constructor() { 
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  public printResults(results:Array<PersonResults>, filter:Filter, importOptions:ImportOptions){
    var docDefinition = { content: [], styles:{} };
    // Make title
    var title = {
      text: "Tree Sweeper Results\n\n",
      style: "title",
    };
    docDefinition.content.push(title);
    // Display the options the tree sweep used
    var optionsHeader = {
      text: "Options Used\n\n",
      style: "header"
    }
    docDefinition.content.push(optionsHeader);
    var options = {
      alignment: "center",
      style: "content",
      columns: [
        {
          text: "Starting PID: " + importOptions.rootPid
        },
        {
          text: "Constraint Group: " + importOptions.constraintGroup
        },
        {
          text: "Number of Generations: " + importOptions.numGenerations
        }
      ]
    }
    docDefinition.content.push(options);
    // Display the constraints that were used
    var constraintHeader = {
      text: "\nConstraints Used\n\n",
      style: "header"
    };
    docDefinition.content.push(constraintHeader);
    var constraints = {
      alignment: "center",
      style: "content",
      columns: []
    }
    let constraintsPerColumn = Math.floor(importOptions.enabledConstraints.length / 3);
    // Build first column
    var text = "";
    for(var i = 0; i < constraintsPerColumn; i++){
      text += importOptions.enabledConstraints[i] + "\n\n";
    }
    constraints.columns.push({text: text, width: "auto"});
    // Build second column
    text = "";
    for(var i = constraintsPerColumn; i < constraintsPerColumn * 2; i++){
      text += importOptions.enabledConstraints[i] + "\n\n";
    }
    constraints.columns.push({text: text, width: "auto"});
    // Build third column
    text = "";
    for(var i = constraintsPerColumn * 2; i < importOptions.enabledConstraints.length; i++){
      text += importOptions.enabledConstraints[i] + "\n\n";
    }
    constraints.columns.push({text: text, width: "auto"});
    docDefinition.content.push(constraints);
    // Display results
    var resultsHeader = {
      text: "\nResults\n\n",
      style: "header"
    };
    docDefinition.content.push(resultsHeader);
    // Display key
    docDefinition.content.push({text:"Key:\n", italics: true, margin:[20,0]})
    let table = {
      table: {
        body: [
          [{text: "Code", bold:true}, "I", "U", "R", "O"],
          [{text: "Result Type", bold:true}, "Impossible", "Unlikely", "Research", "Okay"]
        ]
      },
      margin:[20,0]
    }
    docDefinition.content.push(table);
    docDefinition.content.push("\n\n");
    for(var i = 0; i < results.length; ++i){
      // Start results for person
      let person = results[i];
      var personHeader = {
        alignment: "left",
        columns: [
          {
            text: person.getName(),
            style: "subheader"
          },
          {
            text: "PID: " + person.getPid(),
            style: "content",
            italics: true
          },
          {
            text: "Generation: " + person.getGeneration(),
            style: "content",
            italics: true
          }
        ]
      }
      docDefinition.content.push(personHeader);
      // Print out results for the person
      if(filter.displayImpossible){
        let flagged = person.impossible;
        docDefinition = this.addResults(flagged, docDefinition);
      }
      if(filter.displayUnlikely){
        let flagged = person.unlikely;
        docDefinition = this.addResults(flagged, docDefinition);
      }
      if(filter.displayResearch){
        let flagged = person.research;
        docDefinition = this.addResults(flagged, docDefinition);
      }
      if(filter.displayOkay){
        let flagged = person.okay;
        docDefinition = this.addResults(flagged, docDefinition);
      }
      // Add space before the next person
      docDefinition.content.push("\n");
    }
    // Define styles
    var styles = {
      title: {
        fontSize: 18,
        bold: true,
        alignment: "center"
      },
      header: {
        fontSize: 15,
        bold: true,
        alignment: "left"
      },
      subheader: {
        fontsize: 12,
        bold: true
      },
      content: {
        fontSize: 12,
        bold: false
      }
    };
    docDefinition.styles = styles;
    pdfMake.createPdf(docDefinition).download("treesweeper.pdf");
  }

  private addResults(flagged: Array<FlaggedResult>, docDefinition){
    // For each result in the list...
    for(var i = 0; i < flagged.length; ++i){
      let target = flagged[i];
      // Generate a header with the type of result and message
      let resultHeader = {
        text: [
          {text: this.generatePrefix(target) + ": ", bold:true},
          target.message
        ],
        style: "content",
        margin: [20,0]
      }
      docDefinition.content.push(resultHeader);
      // Generate a list of fixes
      let fixList = {
        ul:[],
        margin:[40,0]
      };
      for(var j = 0; j < target.fixes.length; j++) fixList.ul.push(target.fixes[j]);
      docDefinition.content.push(fixList);
    }

    return docDefinition;
  }

  private generatePrefix(result:FlaggedResult){
    if(result.okay) return "O";
    switch(result.type){
      case "IMPOSSIBLE": return "I";
      case "UNLIKELY": return "U";
      case "RESEARCH": return "R";
      default: return "M"
    }
  }
}
