import { Constraint } from './constraint';
import { nextTick } from 'q';

export class ConstraintCategory {
    public name: string;
    public constraints: Array<Constraint>;

    constructor(name: string){
        this.name = name;
        this.constraints = [];
    }

    public add(id: string): void {
        let constraint: Constraint = new Constraint(id);
        this.constraints.push(constraint);
    }

    public addConstraint(constraint: Constraint): void {
        this.constraints.push(constraint);
    }

    // This method loads with all the currently-defined constraints.
    public loadDefaults(): Array<ConstraintCategory> {
        let constraintCategories: Array<ConstraintCategory> = [];

        // Initialize constraint categories

        // First category: parent-child relationship constraints
        let category: ConstraintCategory = new ConstraintCategory("Parent-Child Relationship");
        let constraint: Constraint = new Constraint("Parent should have a realistic age at birth of children");
        constraint.addId("Father has realistic age at birth of first child");
        constraint.addId("Mother has realistic age at birth of first child");
        constraint.addId("Father has realistic age at birth of last child");
        constraint.addId("Mother has realistic age at birth of last child");
        category.addConstraint(constraint);

        constraint = new Constraint("Parent's children should be born during their lifespan");
        constraint.addId("Parent is born before their children");
        constraint.addId("Parent dies after children are born");
        category.addConstraint(constraint);

        constraint = new Constraint("Person should not have too many parents");
        constraint.addId("Person does not have too many parents");
        category.addConstraint(constraint);

        constraint = new Constraint("Person should not be their own ancestor");
        constraint.addId("Person is not their own ancestor");
        category.addConstraint(constraint);
        constraintCategories.push(category);

        // Second category: christening constraints
        category = new ConstraintCategory("Christening");
        constraint = new Constraint("Person should be christened at a reasonable age");
        constraint.addId("Person was christened at a reasonable age");
        category.addConstraint(constraint);

        constraint = new Constraint("Person should be christened in their lifespan");
        constraint.addId("Person was christened after they were born");
        constraint.addId("Person was christened before they died");
        category.addConstraint(constraint);
        constraintCategories.push(category);

        // Third category: lifespan constraints
        category = new ConstraintCategory("Lifespan");
        constraint = new Constraint("Person should have a reasonable lifespan");
        constraint.addId("Woman has a reasonable lifespan");
        constraint.addId("Man has a reasonable lifespan");
        category.addConstraint(constraint);

        constraint = new Constraint("Person should die after they were born");
        constraint.addId("Person died after they were born");
        category.addConstraint(constraint);
        constraintCategories.push(category);

        // Fourth category: marriage constraints
        category = new ConstraintCategory("Marriage");
        constraint = new Constraint("Person should have a reasonable age at marriage");
        constraint.addId("Husband has realistic age at marriage");
        constraint.addId("Wife has realistic age at marriage");
        category.addConstraint(constraint);

        constraint = new Constraint("Person should be married in their lifespan");
        constraint.addId("Person was alive at marriage");
        constraint.addId("Person was born at marriage");
        category.addConstraint(constraint);
        
        constraint = new Constraint("Couple's children should be born a reasonable amount of time after marriage");
        constraint.addId("Couple has been married a reasonable amount of time before first child");
        constraint.addId("Couple has been married a reasonable amount of time before last child");
        category.addConstraint(constraint);

        constraint = new Constraint("Couple's ages should not be too different");
        constraint.addId("Age gap between spouses is not too large");
        category.addConstraint(constraint);
        constraintCategories.push(category);

        // Fifth category: research constraints
        category = new ConstraintCategory("Research");
        constraint = new Constraint("Person should have sources");
        constraint.addId("Person has sources");
        category.addConstraint(constraint);

        constraint = new Constraint("Couple should not be missing children");
        constraint.addId("Couple is not missing children at beginning of their marriage");
        constraint.addId("Couple is not missing children at the end of mothers child bearing years");
        constraint.addId("Couple is not missing middle children");
        category.addConstraint(constraint);

        constraint = new Constraint("Person should not be missing a spouse");
        constraint.addId("Person is not missing a spouse");
        category.addConstraint(constraint);

        constraint = new Constraint("Person should have a known gender");
        constraint.addId("Person has a known gender");
        category.addConstraint(constraint);

        constraint = new Constraint("Person should have vital dates");
        constraint.addId("Person has vital dates");
        category.addConstraint(constraint);

        constraint = new Constraint("Parents should not have duplicate children");
        constraint.addId("Parent has no duplicate children");
        category.addConstraint(constraint);

        constraint = new Constraint("Children should not have duplicate parents");
        constraint.addId("Child has no duplicate parents");
        category.addConstraint(constraint);

        constraint = new Constraint("People should not have duplicate spouses");
        constraint.addId("Person has no duplicate spouses");
        category.addConstraint(constraint);
        constraintCategories.push(category);

        return constraintCategories;
    }
}