import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HelpComponent} from './help/help.component';
import { StartComponent} from './start/start.component';
import { AuthGuardService } from './auth-guard.service';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';


const routes: Routes = [
  { path: '', redirectTo: '/start', pathMatch: 'full' },
  { path: 'start', component: StartComponent },
  { path: 'help', component: HelpComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'expired', component: SessionExpiredComponent },
  {
    path: 'contact',
    component: HelpComponent, 
    resolve: {
      url: 'externalUrlRedirectResolver'
    },
    data: {
      externalUrl: 'https://familytech.byu.edu/contact.html'
    }
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}