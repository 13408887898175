import { Component, OnInit } from '@angular/core';
import { DataStoreService } from '../data-store.service';

@Component({
  selector: 'app-filter-control',
  templateUrl: './filter-control.component.html',
  styleUrls: ['./filter-control.component.scss']
})
export class FilterControlComponent implements OnInit {
  sortMethod:string = "generation";

  sortOptions = ["name","results","generation"];

  constructor(public dataService: DataStoreService) { }

  ngOnInit() {
  }

  public getDescription(option:string){
    switch(option){
      case "name": return "Sort by Name";
      case "results": return "Sort by Most Results";
      case "generation": return "Sort by Generation";
      default: return "Other";
    }
  }
}
