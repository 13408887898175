import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatToolbarModule,
		 MatInputModule, MatProgressSpinnerModule, 
		 MatCardModule, MatIconModule,
		 MatSidenavModule, MatMenuModule, 
		 MatDialogModule, MatGridListModule, 
		 MatList, MatListModule,
		 MatSelectModule, MatExpansionModule,
		 MatCheckboxModule, MatProgressBarModule} from '@angular/material';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  imports: [MatButtonModule, MatToolbarModule, MatInputModule, 
  			MatProgressSpinnerModule, MatCardModule, MatIconModule, 
			MatSidenavModule, MatMenuModule, MatDialogModule, MatGridListModule,
			MatListModule,MatSelectModule, MatExpansionModule,MatCheckboxModule,
		    MatProgressBarModule,MatRadioModule,MatSlideToggleModule,MatTooltipModule],
  exports: [MatButtonModule, MatToolbarModule, MatInputModule, 
  			MatProgressSpinnerModule, MatCardModule, MatIconModule, 
			MatSidenavModule, MatMenuModule, MatDialogModule, MatGridListModule,
			MatListModule,MatSelectModule, MatExpansionModule, MatCheckboxModule,
			MatProgressBarModule,MatRadioModule,MatSlideToggleModule,MatTooltipModule],
})
export class MaterialModule { }