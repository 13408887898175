import { PersonResults } from "./personResults";

export class Filter {
    public displayImpossible:boolean;
    public displayUnlikely:boolean;
    public displayResearch:boolean;
    public displayOkay:boolean;
    public searchString:string;

    constructor(){
        this.displayImpossible = true;
        this.displayUnlikely = true;
        this.displayResearch = true;
        this.displayOkay = true;
        this.searchString = "";
    }

    public clone(): Filter {
        let copy = new Filter();
        copy.displayImpossible = this.displayImpossible;
        copy.displayOkay = this.displayOkay;
        copy.displayResearch = this.displayResearch;
        copy.displayUnlikely = this.displayUnlikely;

        return copy;
    }

    public filter(input:Array<PersonResults>): Array<PersonResults> {
        let filteredResults:Array<PersonResults> = [];
        let filter:Filter = this;
        // For each person results, generate a filtered person's results
        input.forEach(function(personResult){
            // If there is a search term, filter out anyone not matching the search string. 
            // Otherwise, this shouldn't affect it at all.
            if(personResult.matches(filter.searchString)){
                let totalResults = 0;
                let hasImpossibles = false;
                let hasUnlikely = false;
                if(filter.displayImpossible){
                    totalResults += personResult.impossible.length;
                    if(personResult.impossible.length > 0) hasImpossibles = true;
                } 
                if(filter.displayUnlikely){
                    totalResults += personResult.unlikely.length;
                    if(personResult.unlikely.length > 0) hasUnlikely = true;
                } 
                if(filter.displayResearch) totalResults += personResult.research.length;
                if(filter.displayOkay) totalResults += personResult.okay.length;

                // Only add to the filtered results if they have results; if all their results have been filtered out, don't show them.
                if(totalResults > 0){
                    filteredResults.push(personResult);
                }
            }
        });

        return filteredResults;
    }
}