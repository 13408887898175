import { Component, OnInit } from '@angular/core';
import { LinkService } from '../link.service';
import { Router } from '@angular/router';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  private tutorialIndex:number;

  constructor(public link:LinkService, private router:Router) { }

  ngOnInit() {
  }

  openTutorial(){
    this.tutorialIndex = 0;
    $('#exampleModal').modal({});
  }

  getTutorialIndex():number{
    return this.tutorialIndex;
  }

  setTutorialIndex(index:number):void{
    this.tutorialIndex = index;
  }

  getTitle():string{
    switch(this.tutorialIndex){
      case 0: return "Welcome";
      case 1: return "Adjusting Options";
      case 2: return "Changing the Starting Point";
      case 3: return "Starting a Sweep";
      case 4: return "Understanding Flags";
      case 5: return "Understanding Results";
      case 6: return "Marking Things Okay";
      case 7: return "Sorting Results";
      case 8: return "Filtering Results";
      case 9: return "Contacting Us";
      default: return "title";
    }
  }

  getImage():string{
    switch(this.tutorialIndex){
      case 0: return "./assets/icons/treesweeper.jpg";
      case 1: return "./assets/options.png";
      case 2: return "./assets/changing_start.png";
      case 3: return "./assets/started.png";
      case 4: return "./assets/flags.png";
      case 5: return "./assets/results.png";
      case 6: return "./assets/okay.png";
      case 7: return "./assets/sort.png";
      case 8: return "./assets/filter.png";
      case 9: return "./assets/contact.png";
      default: return "./assets/icons/treesweeper.jpg";
    }
  }

  getDescription():string{
    switch(this.tutorialIndex){
      case 0: return "Welcome to Tree Sweeper! This tutorial will show you everything you need to know to get started with Tree Sweeper. Feel free to skip it if you want!";
      case 1: return "The two options you'll change the most are the number of generations and the constraint group. The number of generations is how many generations back from your starting point Tree Sweeper will check, and you can choose 1 to 7. The default is 3 generations, but you're more likely to see results if you check 5 or more. <br><br>The constraint group is the group of possible problems that Tree Sweeper looks for. Each one has a short description to tell you what kind of problems it's looking for.  There's a bunch of them, try out different ones!";
      case 2: return "Sometimes you may want to check the family tree of someone else on FamilySearch, not just your own. You can change the option 'Select Starting Point' to 'Someone Else' and enter the FamilySearch PID for the other person. The PID is an id given by FamilySearch and can be found on their FamilySearch page.<img src='./assets/example-pid-full.png' class='img-fluid'>";
      case 3: return "Once you're ready to run Tree Sweeper, just click 'Start Tree Sweep.' The progress bar will appear and you'll receive messages telling you what Tree Sweeper is doing. Occasionally you may see a message saying that Tree Sweeper is waiting for Family Search servers; this is normal, and may last up to a minute. It means that FamilySearch is busy and Tree Sweeper's requests for information are being delayed.";
      case 4: return "When you start seeing results, you will see 4 flag icons. The icons indicate how important something is. In this image, the numbers also indicate how many results with that type of flag have been received so far. <ul><li>A red flag means that something is impossible or unheard of (a Definite Error).</li><li>A yellow flag means that something is unlikely, but not impossible (a Possible Error).</li><li>A purple flag means something is a Research Opportunity, not really an error.</li><li>A green flag means that you have marked something okay (an Okay Result).</li></ul>";
      case 5: return "When you click on a person to see their results, you will see a list of results that can also be clicked on, along with some information about that person. Clicking on one of their results will cause it to expand, like in this image. Each result has a flag, a message describing what might be wrong, and some prompts with information to verify.";
      case 6: return "It is likely that sometimes you'll get a result that you know isn't a problem. When this happens, you can open that result and click the 'Mark Okay' button. By default, things that you mark okay will be hidden from view. At this time, Tree Sweeper won't remember what you've marked okay after you run another tree sweep.";
      case 7: return "By default, Tree Sweeper sorts people by generation; that is, the number of generations they are back from you, with your most recent ancestors at the top of the list. However, you can change it to just sort by first name, or by most results.";
      case 8: return "You can also choose to filter out certain results if you just aren't interested in them. Each result type can be filtered out, with 'Okay' results filtered out by default. Additionally, you can search for someone's name or FamilySearch PID. Their PID is a unique id assigned by FamilySearch and you can find it on their FamilySearch page.";
      case 9: return "We would love to hear from you! If you have questions, comments, concerns, or suggestions please contact us! You can email us at fhtlab@gmail.com. We really appreciate feedback on how to make this app better!";
      default: return "";
    }
  }
}
