import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FsService } from './fs.service';
import { LinkService } from './link.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private fsService: FsService, private router: Router, private link: LinkService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //console.log(route.queryParamMap);    
    if (route.queryParamMap.has('fstoken')) {
      this.fsService.logIn(route.queryParamMap.get('fstoken'));
      this.router.navigate([route.url[0].path]);
      return false;
    } else if (this.fsService.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['start']);
      return false;
    }
  
  }
}
