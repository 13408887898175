import { Injectable } from '@angular/core';
import { ImportOptions } from './importOptions';
import { SocketServiceService } from './socket-service.service';
import { CheckerResults } from './checkerResults';
import { PersonResults } from './personResults';
import { Filter } from './filter';
import { Sorter } from './sorter';
import { FlaggedResult } from './flaggedResult';
import { Update } from './updates';
import { Status } from './status';
import { TreeSweepSocket } from './socket';
import { SerializerService } from './serializer.service';

const OPTIONS_KEY = "treesweeper-options";
const RESULTS_KEY = "treesweeper-results";
const FILTER_KEY = "treesweeper-filters";

@Injectable({
  providedIn: 'root'
})
// This service is responsible for storing data that is shared between components
export class DataStoreService {
  public lastSweepOptions: ImportOptions;
  public lastSweepResults: CheckerResults;
  public rawResults: Array<PersonResults>;
  public filter:Filter;
  public filteredResults: Array<PersonResults>;
  public sorter:Sorter;
  public refinedResults: Array<PersonResults>;
  public message:string;
  public submessage:string;

  public status:Status;
  public socket:TreeSweepSocket

  public useCurrentUser: boolean = true;
  public rootPid = "";
  public rootType:string = "Current User";

  constructor(serializer:SerializerService) { 
    // If there is a stored sweep, load it
    if(sessionStorage.getItem(OPTIONS_KEY)){
      this.lastSweepOptions = ImportOptions.loadFromStorage();
    }
    if(sessionStorage.getItem(RESULTS_KEY)){
      this.lastSweepResults = serializer.deserializeCheckerResults(sessionStorage.getItem(RESULTS_KEY));
    } else {
      this.lastSweepResults = new CheckerResults();
    } 
    // Initialize the filter and sort methods
    this.retrieveFilterState();

    // Initialize arrays for display
    this.rawResults = this.lastSweepResults.resultArray();
    this.filteredResults = this.filter.filter(this.rawResults);
    this.refinedResults = this.sorter.sort(this.filteredResults);
    
    // Initialize messages
    this.message = "";
    this.submessage = "";
  }

  public newSweep(options: ImportOptions): void {
    // Cancel old socket, if it's running
    if(this.socket) this.socket.kill();

    // Update stored sweep options
    this.lastSweepOptions = options;
    sessionStorage.setItem(OPTIONS_KEY, JSON.stringify(options,null,2));

    // Make new results object
    this.lastSweepResults = new CheckerResults();
    sessionStorage.removeItem(RESULTS_KEY);
    sessionStorage.setItem(RESULTS_KEY, JSON.stringify(this.lastSweepResults,null,2));
    
    // Initialize arrays for display
    this.rawResults = this.lastSweepResults.resultArray();
    if(this.filter) this.filter = this.filter.clone();
    else this.filter = new Filter();
    this.filteredResults = this.filter.filter(this.rawResults);
    let sortMethod = this.sorter.getMethod();
    this.sorter = new Sorter(this.filter);
    this.sorter.sortBy(sortMethod);
    this.refinedResults = this.sorter.sort(this.filteredResults);

    // start new sweep
    this.status = new Status(100);
    this.status.setValue(0);
    this.status.loading = true;

    // Open socket connection
    if(window.location.host.startsWith("localhost")){
      // This is a development environment, use unsecured socket
      this.socket = new TreeSweepSocket("ws://" + window.location.host + "/import", this.status, options,this);
    } else {
      // This is a production environment, use secured socket
      this.socket = new TreeSweepSocket("wss://" + window.location.host + "/import", this.status, options,this);
    }    
  }

  public handleUpdate(update:Update){
    update.execute(this);
  }

  public addData(result:FlaggedResult):void{
    this.lastSweepResults.addResult(result);
    this.dataChanged();
  }

  private saveFilterState():void{
    // Store filter and sort status
    let filterState = {
      filter: this.filter,
      sortMethod: this.sorter.getMethod()
    };
    sessionStorage.setItem(FILTER_KEY, JSON.stringify(filterState,null,2));
  }

  private retrieveFilterState():void{
    if(sessionStorage.getItem(FILTER_KEY)){
      let savedState = JSON.parse(sessionStorage.getItem(FILTER_KEY));
      this.filter = new Filter();
      this.filter.displayImpossible = savedState.filter.displayImpossible;
      this.filter.displayOkay = savedState.filter.displayOkay;
      this.filter.displayResearch = savedState.filter.displayResearch;
      this.filter.displayUnlikely = savedState.filter.displayUnlikely;
      this.sorter = new Sorter(this.filter);
      this.sorter.sortBy(savedState.sortMethod);
    }else{
      this.filter = new Filter();
      this.sorter = new Sorter(this.filter);
    }
  }

  public dataChanged():void {
    this.saveFilterState();

    this.rawResults = this.lastSweepResults.resultArray();
    this.filteredResults = this.filter.filter(this.rawResults);
    this.refinedResults = this.sorter.sort(this.filteredResults);
  }

  public changeFilter():void {
    this.saveFilterState();
    this.filteredResults = this.filter.filter(this.rawResults);
    this.refinedResults = this.sorter.sort(this.filteredResults);
  }

  public changeSort(type:string):void{
    this.saveFilterState();
    this.sorter.sortBy(type);
    this.refinedResults = this.sorter.sort(this.filteredResults);
  }

  public saveSweep():void {
    sessionStorage.setItem(RESULTS_KEY, JSON.stringify(this.lastSweepResults,null,2));
  }

  public toggleImpossible():void{ this.filter.displayImpossible = !this.filter.displayImpossible; }
}
