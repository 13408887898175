import { PersonResults } from "./personResults";
import { FlaggedResult } from "./flaggedResult";

const RESULTS_KEY = "treesweeper-results";

export class CheckerResults {
    public rawResults;
    public totalResults:number;
    public numImpossible:number;
    public numUnlikely:number;
    public numResearch:number;

    constructor(){
        this.rawResults = {};
        this.totalResults = 0;
        this.numImpossible = 0;
        this.numUnlikely = 0;
        this.numResearch = 0;
    }

    // Adds a new result, creating a PersonResults container if necessary
    public addResult(result:FlaggedResult): void {
        let results = this.rawResults[result.pid];
        if(!results){
            results = new PersonResults(result.name, result.pid, result.generation);
        }
        results.addResult(result);
        this.rawResults[results.getPid()] = results;
        this.totalResults++;
        if(result.type === "IMPOSSIBLE") ++this.numImpossible;
        else if(result.type === "UNLIKELY") ++this.numUnlikely;
        else if(result.type === "RESEARCH") ++this.numResearch;
    }

    // Generates an array with all results so far
    public resultArray():Array<PersonResults> {
        let results:Array<PersonResults> = [];

        // Get all results in the map
        for(var key in this.rawResults){
            if(this.rawResults.hasOwnProperty(key)) results.push(this.rawResults[key]);
        }

        return results;
    }

    public setTotal(value:number){
        this.totalResults = value;
    }

    public setPerson(person:PersonResults){
        this.rawResults[person.getPid()] = person;
    }
}