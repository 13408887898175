import { ConstraintCategory } from "./constraintCategory";

const OPTIONS_KEY = "treesweeper-options";

export class ImportOptions {
    public numGenerations: number;
    public constraintGroup: string;
    public disabledConstraints: Array<string>;
    public enabledConstraints: Array<string>;
    public rootPid: string;
    public token: string;

    constructor(){
        this.numGenerations = -1;
        this.disabledConstraints = [];
        this.enabledConstraints = [];
        this.rootPid = '';
        this.token = '';
    }

    public setDisabledConstraints(disabledConstraints: Array<string>): void {
        this.disabledConstraints = disabledConstraints;
        let enabledConstraints = [];

        // Load enabled constraints by looking at disabled constraints
        let constraintCategories = new ConstraintCategory("").loadDefaults();
        constraintCategories.forEach(function(category){
            category.constraints.forEach(function(constraint){
                // Check if constraint is disabled
                let enabled: boolean = true;
                disabledConstraints.forEach(function(constraintName){
                    if(constraint.containsId(constraintName)){ enabled = false; }
                });
                // If constraint is enabled, add it to enabled constraints
                if(enabled) enabledConstraints.push(constraint.getId());
            });
        });
        this.enabledConstraints = enabledConstraints;
    }

    public iWork():void {
        console.log("hello world!");
    }

    public static loadFromStorage():ImportOptions{
        if(sessionStorage.getItem(OPTIONS_KEY)){
            let temp = JSON.parse(sessionStorage.getItem(OPTIONS_KEY));
            let options = new ImportOptions();
            return Object.assign(options,temp);
        } else return null;
    }
}