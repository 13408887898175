import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LinkService } from '../link.service';
import { FsService } from '../fs.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public title: string;

  constructor(
    private fsService: FsService, 
    private router: Router, 
    public link: LinkService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) 
  {
    this.title = link.APP_NAME; //Set the title for the page
    this.matIconRegistry.addSvgIcon(
      "tree_sweeper_logo", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/treesweeper.svg")
    );
  }

  ngOnInit() {
    // If the user is just barely logging in, don't display the help hint.
    if(!sessionStorage.getItem('hintStatus')) sessionStorage.setItem('hintStatus', 'noHint');

    let loggedIn = this.fsService.checkLoginStatus();

    // initialize popover
    $(document).ready(function(){
      $('[data-toggle="popover"]').popover(); 
      $(document).on('click', function(){
        $('[data-toggle="popover"]').popover('hide');
      });

      // Display tutorial hint, if it hasn't already been seen this session
      // To make this work, the hint status is set to showHint in the doLogin() method of StartComponent. Bad design, I know, but I couldn't think of another workaround.
      if(sessionStorage.getItem("hintStatus") === 'showHint'){
        sessionStorage.setItem("hintStatus", "viewed");
        $('[data-toggle="popover"]').popover('show');
      }
    });
  }

  goHome() {
    this.router.navigate([this.link.getHome()]);
  }

  goToRoute(link: string) {
    console.log('routing!');
    if (link.includes('http')) {
      window.open(link, '_blank');
    } else if (link === 'home') {
      this.goHome();
    } else {
      this.router.navigate([link]);
    }
  }

}
