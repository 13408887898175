import { FlaggedResult } from "./flaggedResult";
import { Filter } from "./filter";

export class PersonResults {
    public pid:string;
    public generation:number;
    public name:string;
    
    public impossible:Array<FlaggedResult>;
    public unlikely:Array<FlaggedResult>;
    public research:Array<FlaggedResult>;
    public okay:Array<FlaggedResult>;

    constructor(name:string, pid:string, generation:number){
        this.name = name;
        this.pid = pid;
        this.generation = generation;
        
        this.impossible = [];
        this.unlikely = [];
        this.research = [];
        this.okay = [];
    }

    public addResult(result: FlaggedResult): void {
        if(result.okay) this.okay.push(result);
        else if(this.getList(result.type) !== null) this.getList(result.type).push(result);
    }

    public toggleOkay(result: FlaggedResult): void {
        // Remove from current list
        let list:Array<FlaggedResult>;
        if(result.okay) list = this.okay;
        else list = this.getList(result.type);
        if(list !== null){
            for(let i = 0; i < list.length; ++i){
                if(list[i].id === result.id){
                    list.splice(i,1);
                }
            }
        }

        // Toggle okay
        result.okay = !result.okay;

        // Add to correct list
        this.addResult(result);
    }

    public getList(type: string): Array<FlaggedResult> {
        switch(type){
            case "IMPOSSIBLE": return this.impossible;
            case "UNLIKELY": return this.unlikely;
            case "RESEARCH": return this.research;
            default: return null;
        }
    }

    // Determines if the results matches a given string via name or pid. Will return true if the string to check against is undefined, null, or empty.
    public matches(value:string):boolean{
        if(value === undefined || value === null || value.trim().length === 0) return true;
        else{
            value = value.trim();
            return (this.name.includes(value) || this.pid.includes(value));
        } 
    }

    public getPid():string { return this.pid; }

    public getName():string { return this.name; }

    public getGeneration():number { return this.generation; }

    public totalResults(filter:Filter):number {
        let total = 0;
        if(filter.displayImpossible) total += this.impossible.length;
        if(filter.displayUnlikely) total += this.unlikely.length;
        if(filter.displayResearch) total += this.research.length;
        if(filter.displayOkay) total += this.okay.length;
        return total;
    }
}