export class FlaggedResult {
    public type: string;
    public okay: boolean;
    public id: string;
    public message:string;
    public fixes:Array<string>;
    public probability: number;

    public pid:string;
    public name:string;
    public generation:number;

    constructor(){ }
}