import { Component, OnInit, Input } from '@angular/core';
import { FlaggedResult } from '../flaggedResult';
import { PersonResults } from '../personResults';
import { DataStoreService } from '../data-store.service';

@Component({
  selector: 'app-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.scss']
})
export class ResultListComponent implements OnInit {
  @Input() public type:string;
  @Input() public results:Array<FlaggedResult>;
  @Input() public person:PersonResults;

  constructor() { }

  ngOnInit() {
  }
}
