import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../utils.service';
import { FsService } from '../fs.service';
import { LinkService } from '../link.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  public appDescription: string;
  public partnerLogos: string[];

  constructor(private utilsService: UtilsService, private fsService: FsService, public link: LinkService) {
    this.appDescription = "Have you ever been looking at your family tree and found someone who died before they were born? Have you ever wished someone would fix all those annoying mistakes people make? Tree Sweeper will help you find the errors that have been put into your family tree so you can sweep them away!";
    this.partnerLogos = [
      '../../assets/icons/cs-logo.png',
      '../../assets/icons/fhtl.png',
      //'../../assets/icons/fs-logo.png' Uncomment once app is fs certified
    ];
  }

  ngOnInit() {
    
  }

  doLogin() {
    this.fsService.verifyAuthReady();
  }

}
