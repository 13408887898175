import { Component, OnInit, Input } from '@angular/core';
import { PersonResults } from '../personResults';
import { DataStoreService } from '../data-store.service';

@Component({
  selector: 'app-person-results',
  templateUrl: './person-results.component.html',
  styleUrls: ['./person-results.component.scss']
})
export class PersonResultsComponent implements OnInit {
  @Input() public person:PersonResults

  constructor(public dataService:DataStoreService) { }

  ngOnInit() {
  }

  public setRootPerson(pid:string){
    this.dataService.useCurrentUser = false;
    this.dataService.rootType = "Someone Else";
    this.dataService.rootPid = pid;
  }

  public openFamilySearch(pid:string){
    window.open("https://familysearch.org/ark:/61903/4:1:" + pid + "?context=detail", "_blank");
  }

}
