import { Injectable } from '@angular/core';
import { FlaggedResult } from './flaggedResult';
import { PersonResults } from './personResults';
import { CheckerResults } from './checkerResults';

@Injectable({
  providedIn: 'root'
})
export class SerializerService {

  constructor() { }

  private deserializeFlaggedResult(model): FlaggedResult{
    let temp = new FlaggedResult();
    return Object.assign(temp,model);
  }

  private deserializePersonResults(model): PersonResults{
    let temp = new PersonResults(model.name, model.pid, model.generation);
    // Deserialize flagged results
    for(var i = 0; i < model["impossible"].length; ++i){
      let element = model["impossible"][i];
      let result = this.deserializeFlaggedResult(element);
      temp.impossible.push(result);
    }
    for(var i = 0; i < model["unlikely"].length; ++i){
      let element = model["unlikely"][i];
      let result = this.deserializeFlaggedResult(element);
      temp.unlikely.push(result);
    }
    for(var i = 0; i < model["research"].length; ++i){
      let element = model["research"][i];
      let result = this.deserializeFlaggedResult(element);
      temp.research.push(result);
    }
    for(var i = 0; i < model["okay"].length; ++i){
      let element = model["okay"][i];
      let result = this.deserializeFlaggedResult(element);
      temp.okay.push(result);
    }
    
    return temp;
  }

  public deserializeCheckerResults(json:string): CheckerResults {
    let temp = JSON.parse(json);
    let target = new CheckerResults();
    target.setTotal(temp.totalResults);
    target.numImpossible = temp.numImpossible;
    target.numUnlikely = temp.numUnlikely;
    target.numResearch = temp.numResearch;
    // Get all person results
    for(var pid in temp.rawResults){
      if(temp.rawResults.hasOwnProperty(pid)){
        let person = this.deserializePersonResults(temp.rawResults[pid]);
        target.setPerson(person);
      }
    }
    return target;
  }
}
