import { PersonResults } from "./personResults";
import { Filter } from "./filter";

export class Sorter {
    private sortMethod:string;
    private filter:Filter;

    constructor(filter:Filter){
        this.sortMethod = "generation";
        this.filter = filter;
    }

    public getMethod():string{
        return this.sortMethod;
    }

    public sortBy(type:string):void {
        this.sortMethod = type;
    }

    public sort(input:Array<PersonResults>):Array<PersonResults>{
        switch(this.sortMethod){
            case "name": return this.sortByName(input);
            case "results": return this.sortByResults(input);
            case "generation": return this.sortByGeneration(input);
            default: return input;
        }
    }

    private sortByName(input:Array<PersonResults>):Array<PersonResults>{
        return input.sort(function(a, b){
            if(a.getName() < b.getName()) return -1;
            else if(a.getName() === b.getName()) return 0;
            else return 1;
        });
    }

    private sortByResults(input:Array<PersonResults>):Array<PersonResults>{
        let fil = this.filter;
        return input.sort(function(a,b){
            let value = b.totalResults(fil) - a.totalResults(fil);
            if(value !== 0) return value;
            else {
                // If number of results is the same, sort by name.
                if(a.getName() < b.getName()) return -1;
                else if(a.getName() === b.getName()) return 0;
                else return 1;
            }
        });
    }

    private sortByGeneration(input:Array<PersonResults>):Array<PersonResults>{
        return input.sort(function(a,b){
            let value = a.getGeneration() - b.getGeneration();
            if(value !== 0) return value;
            else {
                // If generation is the same, sort by name.
                if(a.getName() < b.getName()) return -1;
                else if(a.getName() === b.getName()) return 0;
                else return 1;
            }
        });
    }
}