import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { LinkService } from './link.service';
import { Router } from '@angular/router';
import { UtilsService } from './utils.service';
import { FHTLX, FsSession } from './familySearchX/FHTLX';

@Injectable({
  providedIn: 'root'
})
export class FsService {

  private isLoggedIn: boolean = false;

  public FhtlX: FHTLX;

  public fsSession: FsSession;

  constructor(private http: HttpClient, private link: LinkService, private router: Router, private utils: UtilsService) {

  }

  verifyAuthReady() {
    const url = this.link.getAuthRedirect(`${window.location.origin}/home`);
    if (url) {
      window.location.assign(url);
    }
  }

  isAuthenticated(): boolean {
    return this.isLoggedIn;
  }

  setLoggedIn(fsSession: FsSession): void {
    this.FhtlX.readyAccessToken(fsSession);
    this.link.setHomeLoggedIn();
    this.isLoggedIn = true;
  }

  logIn(fstoken: string) {
    this.fsSession = this.parseJwt(fstoken);
    this.initFhtlX();
    this.FhtlX.storeSession(this.fsSession);
    this.setLoggedIn(this.fsSession);
  }

  initFhtlX(): void {
    let initOptions: any = {
      environment: 'production',
      appKey: 'app Key :)',
      redirectUri: 'Put your redirect uri here if you want',
      saveAccessToken: true
    }

    this.FhtlX = new FHTLX(initOptions);
  }

  checkLoginStatus(): boolean {
    this.fsSession = FHTLX.checkAccessToken();

    if (!!this.fsSession) {
      this.initFhtlX();
      this.setLoggedIn(this.fsSession);
      return true;
    } else {
      return false;
    }
  }

  logOut() {
    // Clear saved sweep data
    sessionStorage.removeItem("treesweeper-options");
    sessionStorage.removeItem("treesweeper-results");

    let loaderRef = this.utils.displayLoader('Logging out...', false);
    loaderRef.afterClosed().subscribe(() => {
      return this.router.navigate(['start']);
    })
    if (!this.FhtlX) {
      loaderRef.close();
      //return this.router.navigate(['start']);
    } else {
      return this.FhtlX.logout(() => {
        this.FhtlX.deleteSession();
        this.isLoggedIn = false;
        this.link.logOut();
        loaderRef.close();
        //return this.router.navigate(['start']);
      });
    }
  }

  parseJwt(token: string): FsSession {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}
