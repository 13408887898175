import { Component, OnInit } from '@angular/core';
import { ConstraintCategory } from '../constraintCategory';
import { ImportOptions } from '../importOptions';
import { FsService } from '../fs.service';
import { DataStoreService } from '../data-store.service';

@Component({
  selector: 'app-sweep-options',
  templateUrl: './sweep-options.component.html',
  styleUrls: ['./sweep-options.component.scss']
})
export class SweepOptionsComponent implements OnInit {
  public generationOptions: Array<string> = ["1", "2", "3", "4", "5", "6", "7"];
  public numGenerations: string = "3";
  public constraintGroup: string = "No Research";
  public isCustom: boolean = false;
  public constraintCategories: Array<ConstraintCategory>;

  constructor(public fsService: FsService, public dataService: DataStoreService) { }

  ngOnInit() {
    // This would call a static method if I wanted to take the time to figure it out.
    this.constraintCategories = new ConstraintCategory("").loadDefaults();
    this.selectAllButResearch();
  }

  // Starts a new tree sweep using the options currently specified
  public startSweep(): void {
    // Generate constraint options object
    let importOptions = new ImportOptions();
    importOptions.constraintGroup = this.constraintGroup;
    importOptions.numGenerations = parseInt(this.numGenerations);
    if(this.dataService.useCurrentUser){
      // Use current user's pid as root
      importOptions.rootPid = this.fsService.fsSession.fs_user.pid;
    } else {
      // Use manual pid as root
      importOptions.rootPid = this.dataService.rootPid.trim();;
    }
    importOptions.token = this.fsService.fsSession.fs_access_token;

    // Determine which constraints are disabled
    let disabledConstraints: Array<string> = [];
    this.constraintCategories.forEach(
      function(category){
        category.constraints.forEach(
          function(constraint){
            if(!constraint.isEnabled()){
              let ids:string[] = constraint.getIds();
              ids.forEach(function(id){
                disabledConstraints.push(id);
              });
            }
          }
        );
      }
    );
    importOptions.setDisabledConstraints(disabledConstraints);

    // Start sweep
    this.dataService.newSweep(importOptions);
  }

  // Returns a description of the current constraint category
  public getDescription(): string {
    switch(this.constraintGroup){
      case "All": return "This group checks for everything: definite errors, possible errors, and research opportunities.";
      case "Impossible": return "This group only looks for definite errors. Any results you see indicate that something is definitely incorrect.";
      case "Research": return "These constraints only give research opportunities. They may not tell you any problems, but will give you ideas of where to do your own research.";
      case "Custom": return "Choose what you want to look for!";
      case "No Research": return "This group only looks for possible errors and will not give you any additional research opportunities.";
      default: return "";
    }
  }

  // Enables all constraints
  public enableConstraints(): void {
    this.constraintCategories.forEach(
      function(category){
        category.constraints.forEach(
          function(constraint){ constraint.setEnabled(true); }
        );
      }
    );
  }

  // Disables all constraints
  public disableConstraints(): void {
    this.constraintCategories.forEach(
      function(category){
        category.constraints.forEach(
          function(constraint){ constraint.setEnabled(false); }
        );
      }
    );
  }

  // Enables a specific constraint
  public enableConstraint(categoryName: string, constraintName: string): void {
    this.constraintCategories.forEach(
      function(category){
        if(category.name === categoryName) category.constraints.forEach(
          function(constraint){
            if(constraint.getId() === constraintName) constraint.setEnabled(true);
          }
        );
      }
    );
  }

  // Disables a specific constraint
  public disableConstraint(categoryName: string, constraintName: string): void {
    this.constraintCategories.forEach(
      function(category){
        if(category.name === categoryName) category.constraints.forEach(
          function(constraint){
            if(constraint.getId() === constraintName) constraint.setEnabled(false);
          }
        );
      }
    );
  }

  // Enables all constraints and sets the constraint group to "All"
  public selectAllConstraints(): void {
    this.constraintGroup = "All";
    this.isCustom = false;
    this.enableConstraints();
  }

  public selectAllButResearch(): void {
    this.constraintGroup = "No Research";
    this.isCustom = false;
    this.enableConstraints();

    // Disable research constraints
    this.disableConstraint("Research", "Person should have sources");
    this.disableConstraint("Research", "Couple should not be missing children");
    this.disableConstraint("Research", "Person should not be missing a spouse");
    this.disableConstraint("Research", "Person should have a known gender");
    this.disableConstraint("Research", "Person should have vital dates");
    this.disableConstraint("Research", "Parents should not have duplicate children");
    this.disableConstraint("Research", "Children should not have duplicate parents");
    this.disableConstraint("Research", "People should not have duplicate spouses");
  }

  // Enables only impossibility constraints and sets the constraint group to "Impossible"
  public selectImpossibleConstraints(): void {
    this.constraintGroup = "Impossible";
    this.isCustom = false;
    this.disableConstraints();

    // Enable constraints that return impossibilities
    this.enableConstraint("Christening", "Person should be christened in their lifespan");
    this.enableConstraint("Parent-Child Relationship", "Parent's children should be born during their lifespan");
    this.enableConstraint("Parent-Child Relationship", "Person should not have too many parents");
    this.enableConstraint("Parent-Child Relationship", "Person should not be their own ancestor");
    this.enableConstraint("Marriage", "Person should be married in their lifespan");
    this.enableConstraint("Lifespan", "Person should die after they were born");
  }

  // Enables only research constraints and sets the constraint group to "Research"
  public selectResearchConstraints(): void {
    this.constraintGroup = "Research";
    this.isCustom = false;
    this.disableConstraints();

    // Enable constraints that return research opportunities
    this.enableConstraint("Research", "Person should have sources");
    this.enableConstraint("Research", "Couple should not be missing children");
    this.enableConstraint("Research", "Person should not be missing a spouse");
    this.enableConstraint("Research", "Person should have a known gender");
    this.enableConstraint("Research", "Person should have vital dates");
    this.enableConstraint("Research", "Parents should not have duplicate children");
    this.enableConstraint("Research", "Children should not have duplicate parents");
    this.enableConstraint("Research", "People should not have duplicate spouses");
  }

  // Disables all constraints, sets the constraint group to "Custom", and exposes constraints for manual selection
  public selectCustomConstraints(): void {
    this.constraintGroup = "Custom";
    this.isCustom = true;
    this.disableConstraints();
  }
}
