import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { LoaderComponent } from './loader/loader.component';
import { LogoutComponent } from './logout/logout.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { StartComponent } from './start/start.component';
import { SweepOptionsComponent } from './sweep-options/sweep-options.component';
import { SweepResultsComponent } from './sweep-results/sweep-results.component';
import { AppRoutingModule } from './app-routing.module';
import { HelpComponent } from './help/help.component';
import { MaterialModule } from './material.module';

import { LinkService } from './link.service';
import { FsService } from './fs.service';
import { AppBootstrapModule } from './/app-bootstrap.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterControlComponent } from './filter-control/filter-control.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { LastSweepSummaryComponent } from './last-sweep-summary/last-sweep-summary.component';
import { ResultListComponent } from './result-list/result-list.component';
import { PersonResultsComponent } from './person-results/person-results.component';
import { ResultComponentComponent } from './result-component/result-component.component';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    LoaderComponent,
    LogoutComponent,
    SessionExpiredComponent,
    StartComponent,
    SweepOptionsComponent,
    SweepResultsComponent,
    HelpComponent,
    FilterControlComponent,
    LoadingIndicatorComponent,
    LastSweepSummaryComponent,
    ResultListComponent,
    PersonResultsComponent,
    ResultComponentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    AppRoutingModule,
    AppBootstrapModule,
    FormsModule
  ],
  entryComponents: [
    LoaderComponent
  ],
  providers: [LinkService, FsService, {
    provide: 'externalUrlRedirectResolver',
    useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => 
    {
      window.location.href = (route.data as any).externalUrl;
    }
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
