import { Status } from './status';
import { ImportOptions } from './importOptions';
import { Update } from './updates';
import { DataStoreService } from './data-store.service';

export class TreeSweepSocket {
    public socket:WebSocket;

    constructor(url:string, status:Status, options:ImportOptions, dataService:DataStoreService){
        // Open the connection
        let socket = new WebSocket(url);
        this.socket = socket;

        // Define behavior for different events
        socket.onopen = function(){
            socket.send(JSON.stringify(options,null,2));
            keepAlive();
        }
        socket.onmessage = function(message){
            // First, figure out what type of message this is
            try{
                let input = JSON.parse(message.data);
                try{
                    let update:Update = Update.typeOfUpdate(input);
                    dataService.handleUpdate(update);
                }catch(err){
                    // Something went wrong while deserializing and classifying the object
                    console.error(err);
                }
            }catch(err){
                // Not an object
                console.log(message.data);
            }
        }
        socket.onclose = function(){
            status.loading = false;
            cancelKeepAlive();
            dataService.saveSweep();
        }
        socket.onerror = function(error){
            status.loading = false;
            console.error(error.type);
            cancelKeepAlive();
            dataService.saveSweep();
        }

        // These functions are necessary for keeping the socket open
        let timerId;
        let keepAlive = function(){
            let timeout = 5000;
            if(socket.readyState === socket.OPEN) socket.send("");
            timerId = setTimeout(keepAlive, timeout);
        }
        let cancelKeepAlive = function(){
            if(timerId) clearTimeout(timerId);
        }
    }

    public kill():void{
        if(this.socket)this.socket.close();
    }
}