import { Component, OnInit, Input } from '@angular/core';
import { FlaggedResult } from '../flaggedResult';
import { DataStoreService } from '../data-store.service';
import { PersonResults } from '../personResults';

@Component({
  selector: 'app-result-component',
  templateUrl: './result-component.component.html',
  styleUrls: ['./result-component.component.scss']
})
export class ResultComponentComponent implements OnInit {
  @Input() public result:FlaggedResult = null;
  @Input() public type:string = '';
  @Input() public person:PersonResults = null;

  public displayProbability:boolean = false;

  constructor(private dataService:DataStoreService) { }

  ngOnInit() {
  }

  public toggleOkay(person:PersonResults, result:FlaggedResult){
    person.toggleOkay(result);
    this.dataService.dataChanged();
  }

  public flagColor(){
    switch(this.type){
      case "IMPOSSIBLE": return "red";
      case "UNLIKELY": return "yellow";
      case "RESEARCH": return "purple";
      case "OKAY": return "green";
      default: return "blue";
    }
  }

  public tooltipText(result:FlaggedResult){
    switch(this.type){
      case "IMPOSSIBLE": return "This is impossible.";
      case "UNLIKELY": return "This is unlikely; the probability is " + this.prettyProbability(result.probability);
      case "RESEARCH": return "This could use more research.";
      case "OKAY": return "This has been marked OK.";
      default: return "";
    }
  }

  public prettyProbability(probability:number){
    let percent = probability * 100;
    return percent.toFixed(4) + "%";
  }

  public probabilityButtonText():string{
    if(this.displayProbability) return "Hide Probability"
    else return "Show Probability"
  }

  public probabilityButtonIcon():string{
    if(this.displayProbability) return "visibility_off";
    else return "visibility";
  }

  public okayButtonIcon():string{
    if(this.type === "OKAY") return "cancel";
    else return "check_circle_outline";
  }

  public okayButtonText():string{
    if(this.type === "OKAY") return "Mark Not OK";
    else return "Mark OK";
  }
}
