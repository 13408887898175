export class Constraint {
    public id: string;
    public enabled: boolean;
    public ids: string[];

    constructor(id: string){
        this.id = id;
        this.enabled = true;
        this.ids = [];
    }

    public isEnabled(): boolean {
        return this.enabled;
    }

    public setEnabled(enabled: boolean): void {
        this.enabled = enabled;
    }

    public getId(): string {
        return this.id;
    }

    public addId(id:string): void {
        if(!this.ids) this.ids = [];
        this.ids.push(id);
    }

    public getIds(): string[]{
        return this.ids;
    }

    public containsId(id:string):boolean{
        return this.ids.includes(id);
    }
}